/** adapted from https://github.com/coolgk/utils/blob/master/oauth-pkce/src/index.standalone.ts */
/**
 * Spec https://tools.ietf.org/html/rfc7636#section-4.1
 */

declare global {
  interface Window {
    msCrypto: {
      getRandomValues: (array: Uint8Array) => Uint8Array
      subtle: {
        digest: (method: string, seed: unknown) => CryptoOperation | PromiseLike<ArrayBuffer>
      }
    }
    CryptoOperation: unknown
  }
}

type CryptoOperation = {
  [index: string]: unknown
}

type Callback = (error: Error | null, value: { verifier: string; challenge: string }) => void

// eslint-disable-next-line id-blacklist
function b64Uri(string: string) {
  // https://tools.ietf.org/html/rfc4648#section-5
  return btoa(string).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')
}

export function getPkce(length: number | undefined, callback: Callback): void {
  if (!length) {
    length = 43
  }
  const cryptoLib = window.msCrypto || window.crypto

  const verifier = b64Uri(
    Array.prototype.map
      .call(cryptoLib.getRandomValues(new Uint8Array(length)), num => String.fromCharCode(num))
      .join('')
  ).substring(0, length)

  const randomArray = new Uint8Array(verifier.length)
  for (let i = 0; i < verifier.length; i++) {
    randomArray[i] = verifier.charCodeAt(i)
  }
  const rawDigest = cryptoLib.subtle.digest('SHA-256', randomArray)

  if (window.CryptoOperation) {
    const digest = rawDigest as CryptoOperation
    digest.onerror = callback
    digest.oncomplete = (event: { target: { result: ArrayBuffer } }) =>
      runCallback(callback, verifier, event.target.result)
  } else {
    const digest = rawDigest as Promise<ArrayBuffer>
    digest
      .then(digest => {
        runCallback(callback, verifier, digest)
      })
      // @ts-ignore
      .catch(callback)
  }
}

function runCallback(callback: Callback, verifier: string, digest: ArrayBuffer): void {
  callback(null, {
    verifier,
    challenge: b64Uri(String.fromCharCode.apply(null, new Uint8Array(digest) as unknown as number[])),
  })
}
