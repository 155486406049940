/** Image sizes */

import type { Image, ImageSizeType, Persisted } from '@tunasong/schemas'

export interface ImageSize {
  width: number
  height: number
}

export const getImageSize = (type: ImageSizeType): ImageSize => {
  switch (type) {
    case 'thumbnail':
      return { width: 64, height: 64 }
    case 'tiny':
      return { width: 128, height: 128 }
    case 'small':
      return { width: 320, height: 320 }
    case 'medium':
      return { width: 640, height: 640 }
    case 'large':
      return { width: 1024, height: 1024 }
    case 'full':
      return { width: 2048, height: 2048 }

    default:
      throw new Error(`Unknown image size: ${type}`)
  }
}

export const getImageSizeString = (type: ImageSizeType): string => {
  const { width, height } = getImageSize(type)
  return `${width}x${height}`
}

export const getImageUrl = (image: Persisted<Image>, size: ImageSizeType): string =>
  `${image.storageUrls?.url}&d=${getImageSizeString(size)}`
