/**
 * Global configuration
 *
 * Configure per package
 */

import { hotkeySchema, maturitySchema } from '@tunasong/schemas'
import { z } from 'zod'
import packageJSON from '../package.json' with { type: 'json' }
import type { AppManifest } from './manifest.types.js'

const projectName = 'tunasong'

/** @todo these should be in the plugins and "injected" into the manifest (e.g., during build?) */
export const midiPluginSchema = z
  .object({
    midi: z.boolean().describe('MIDI support').default(false),
    btMidi: z.boolean().describe('Bluetooth MIDI support (experimental)').default(false),
    btMidiAutoConnect: z.boolean().describe('Auto-connect to paired Bluetooth MIDI devices').default(false),
  })
  .optional()

export const chordPlayerPluginSchema = z
  .object({
    play: z.boolean().describe('Play chords on selection').default(false),
  })
  .optional()

export const chordPluginSchema = z
  .object({
    showTranspose: z.boolean().describe('Show transposition with the chord, e.g., +2').default(true),
  })
  .optional()

/** Manifest starts here */

const manifest = {
  name: projectName,
  version: packageJSON.version,

  releases: [
    { version: '0.4.0', entityId: '95ws3h6s3gov6bbqaemomk4vs' },
    { version: '0.5.0', entityId: '23wsp40vamzq0gkzeyka6zg3l' },
    { version: '0.6.0', entityId: 'b90oy0hmad20vshx7msol6591' },
    { version: '0.6.1', entityId: '896o7olq2ozaz871iud1f58h0' },
    { version: '0.7.0', entityId: '9g05gmqhrjjoelyg31gyjrtn1' },
    { version: '0.8.0', entityId: '5z3tpiz6b2qmdnhl6wio9h355' },
    { version: '0.9.0', entityId: '2mswirvu6bthu1i2ac0hrmzcu' },
    { version: '0.10.0', entityId: '6cx7szzcksgg1xini116tn4bn' },
    { version: '0.11.0', entityId: 'cps42si6kl8eawo9fs4sp5kx4' },
    { version: '0.12.0', entityId: '1f364549lqkg7u1t04h3qdatr' },
    { version: '0.13.0', entityId: 'ak4c4h14gb2vrd1i9vbor586h' },
  ],

  entityTypeMimeExtensions: {
    // Entity / plugin type to mimeType
    // We keep this here to avoid having to instantiate all plugins to extract these.
    audio: { 'audio/*': ['.mp3', '.wav', '.ogg', '.m4a', '.aac', '.mp4', '.webm', '.flac'] },
    image: { 'image/*': ['.jpg', '.jpeg', '.png', '.gif', '.svg', '.webp'] },
    pdf: { 'application/pdf': ['.pdf'] },
    page: { 'text/markdown': ['.md'] },
    calendar: { 'text/calendar': ['.ical'] },
    evernote: { 'application/xml': ['.enex'] },
    musicxml: {
      'application/vnd.recordare.musicxml+xml': ['.musicxml'],
      'application/vnd.recordare.musicxml': ['.mxl'],
    },
    video: {
      'video/*': [
        '.mp4',
        '.webm',
        '.ogg',
        '.ogv',
        '.mov',
        '.avi',
        '.wmv',
        '.flv',
        '.m4v',
        '.mpg',
        '.mpeg',
        '.3gp',
        '.3g2',
      ],
    },
  },

  // The host (i.e., hostname:port). Either a string (exact match) or a Regexp to match the host.
  // These will be validated with custom lambdas proxies for login / logout.
  appHosts: [
    'tunasong.com',
    /^.*\.preview\.tunasong\.com$/,
    'localhost:8000',
    // nGrok
    'directly-honest-donkey.ngrok-free.app',
  ],

  /** This config should seldom be changed */
  tables: {
    entities: `${projectName}_entities`,
    entityUpdates: `${projectName}_entities_updates`,
    edges: `${projectName}_entities_graph`,
  },
  schemas: {},

  search: {
    createEmbeddingsForTypes: ['page', 'song'],
    /** don't index these types */
    skipIndexForTypes: [
      'trackerdata',
      'profile',
      'usersettings',
      'audiotrack',
      'midi',
      'pollresponse',
      'entitylink',
      'link',
    ],
    /** Keep these in search, but exclude from some views */
    excludeFromDefault: ['comment', 'audiocomment', 'profile', 'calendarevent'],
    excludeFromRecent: ['comment', 'audiocomment', 'profile', 'calendar', 'note', 'folder'],
  },
  /** entity types where we want push to clients on change */
  push: {
    entityTypes: ['comment', 'audiocomment', 'audio', 'page', 'song', 'calendarevent', 'folder'],
  },

  /**
   * Config is typically implemented by the plugin with the key
   * @todo why is this in the manifest? There was a good reason, now forgotten.
   * It makes sense to have config values here, but the schema?
   * Ideally, we define these in the plugin, and the manifest is just a list of keys.
   */
  userConfig: z.object({
    featureMaturity: maturitySchema.optional().default('stable'),
    theme: z.enum(['light', 'dark', 'system']).optional().default('system'),
    /** Show content in columns when the horizontal space allows it */
    showContentColumns: z
      .boolean()
      .describe('Show content in columns in Performance layouts')
      .optional()
      .default(false),
    /** Favorites - entity IDs of the user's favorites */
    favorites: z.array(z.string()).optional(),
    /** AI Configuration */
    ai: z.record(z.unknown()).optional(),
    /** The keyboard shortcuts / hotkeys for the user. Format handled by the useHotkeys hook */
    hotkeys: z.array(hotkeySchema).default([
      { hotkey: 'opt+up', label: 'Block: Move Up', commandId: 'block-move-up' },
      { hotkey: 'opt+down', label: 'Block: Move Down', commandId: 'block-move-down' },
      { hotkey: 'shift+mod+d', label: 'Block: Duplicate', commandId: 'block-duplicate' },
      { hotkey: 'shift+mod+backspace', label: 'Block: Delete', commandId: 'block-delete' },
      { hotkey: 'mod+b', label: 'Format: Bold', commandId: 'format-bold' },
      { hotkey: 'mod+i', label: 'Format: Italic', commandId: 'format-italic' },
    ]),
    musicPreferences: z
      .object({
        transpose: z.number().optional(),
        noteFormat: z.enum(['standard', 'norwegian']).optional(), // Norwegian uses H instead of B
      })
      .optional(),
    plugins: z
      .object({
        // The key is the plugin key / id
        midi: midiPluginSchema,
        'chord-player': chordPlayerPluginSchema,
        chord: chordPluginSchema,
      })
      .passthrough(),
  }),
} satisfies AppManifest

export type UserConfig = z.infer<typeof manifest.userConfig>

export const defaultUserConfig = manifest.userConfig.parse({ plugins: {} })

export default manifest
