import { Functions } from '@tunasong/icons'
import { logger } from '@tunasong/models'
import type { Entity, EntityCommandSpec, Persisted } from '@tunasong/schemas'
import type { Command } from '../plugin-types.js'
import { generateCommandSpecsFromCustomCommand } from '../sandbox/command-sandbox.js'

export const getCustomCommands = ({
  cmdEntity,
  runner,
  targetEntity,
}: {
  // The entity the custom command is defined on
  cmdEntity?: Persisted<Entity>
  targetEntity?: Persisted<Entity>
  runner?: (({ spec, targetEntityId }: { spec: EntityCommandSpec; targetEntityId: string }) => unknown) | null
}): Command[] =>
  runner && cmdEntity && targetEntity
    ? (cmdEntity.customCommands ?? []).map(command => {
        const { jsCode, commandParams } = command
        const cmd = async () => {
          const result = generateCommandSpecsFromCustomCommand({
            scope: {
              targetEntity,
              cmdEntity,
              cmdParams: commandParams,
            },
            code: jsCode,
          })
          // execute the specs
          logger.debug('Running custom command', command.label, result)
          if (!result.success) {
            throw new Error(`Error running custom command ${command.label}: ${result.error.message}`)
          }
          for (const spec of result.data) {
            await runner({ spec, targetEntityId: targetEntity.id })
          }
        }

        const name = `${cmdEntity.name} ${command.label} (Custom)`
        return {
          id: command.commandId,
          name,
          Icon: Functions,
          cmd,
        }
      })
    : []
