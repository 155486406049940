import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { AuthData } from '../../auth/auth-data.js'

// Define a type for the slice state

type AuthProvider = 'email' | 'Google'
export interface UserState {
  authData?: AuthData
  isLoggedIn: boolean
  userId?: string
  email?: string
  name?: string
  picture?: string
  authProvider: AuthProvider
}

// Define the initial state using that type
const initialState: UserState = {
  authData: undefined,
  isLoggedIn: false,
  userId: undefined,
  email: undefined,
  name: undefined,
  picture: undefined,
  authProvider: 'email',
}

/**
 * @note if you get an Immer error here, pin the Immer version to ensure a single instance
 * @see https://github.com/reduxjs/redux-toolkit/issues/1181
 */
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<AuthData>) => {
      state.authData = action.payload
      state.isLoggedIn = Boolean(state.authData)
      state.userId = action.payload.idToken?.payload?.sub
      state.email = action.payload.idToken?.payload?.email
      state.name = action.payload.idToken?.payload?.name ?? action.payload.idToken?.payload?.email
      state.picture = action.payload.idToken?.payload?.picture

      const providerName = action.payload.idToken?.payload?.identities?.find(
        identity => identity.primary === 'true'
      )?.providerName

      state.authProvider = providerName ? (providerName as AuthProvider) : 'email'
    },

    /** Refresh the OAuth session. */
    refreshSession: state => {
      state.isLoggedIn = false
    },
    signout: state => {
      Object.assign(state, initialState)
    },
  },
})
