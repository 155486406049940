import type { FetchArgs } from '@reduxjs/toolkit/query'
import { createApi } from '@reduxjs/toolkit/query/react'
import {
  decorateParentAndACLs,
  dedupe,
  isPersistedEntity,
  isStoredObject,
  logger,
  removeEntityReserved,
} from '@tunasong/models'
import type { EntityUpdate, EntityVersion, Invite, ShareItem, Slug } from '@tunasong/models'
import type { ACL, Edge, EdgeType, Entity, EntityType, Persisted } from '@tunasong/schemas'
import type { Draft } from 'immer'
import { entitiesSlice } from '../features/entities/entities-slice.js'
import { notificationsSlice } from '../features/notifications/notifications-slice.js'
import baseQuery from './base-query.js'
import { clearYDocCache } from './ydoc-cache.js'
import type { Dispatch } from 'redux'

/** StartKey for API. This is opaque for clients (but is a url encoded JSON string) */
export type StartKey = string

/** The default fields to load when loading   all entities */
const defaultFields = [
  'id',
  'parentId',
  'userId',
  'type',
  'acls',
  'name',
  'tags',
  'starred',
  'media',
  'description',
  /** Folder */
  'options',
  /** Audio */
  'url',
  'storage',
  'features',
  'overdub',
  'regions',
  /** Playlist */
  'mediaIds',
  /** Common */
  'trash',
  'metadata',
  'createdAt',
  'updatedAt',
]

/** id for partial list for pagination @see https://redux-toolkit.js.org/rtk-query/usage/pagination#automated-re-fetching-of-paginated-queries */
export const PARTIAL_LIST = 'PARTIAL_LIST'

const loadAllProps = { partial: true, trash: false, fields: defaultFields }

/** Update the loadEntity store with a batch of entities or partial entities */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateEntitiesStore = <TDispatch extends Dispatch<any>, TState extends { 'entities-api': any } = any>({
  entities,
  dispatch,
  state,
  removedIds = [],
  updateParent = false,
}: {
  /** Entities or partial entities */
  entities: (Partial<Persisted<Entity>> & { id: string; updatedAt: string })[]
  dispatch: TDispatch
  state: TState
  // ids that have been removed in the result set from earlier
  removedIds?: string[]
  // update the root and parent/child queries. Default is false. Be careful with this as it can cause infinite loops
  updateParent?: boolean
}) => {
  /** Register the data in the loadEntity cache */
  for (const entity of entities) {
    /** Check if we already have the entity in the store */
    const { data: existing } = entitiesApi.endpoints.loadEntity.select({ id: entity.id })(state)

    const updatedEntity = { ...existing, ...entity }

    // We can only update the store if we have a complete entity
    if (!isPersistedEntity(updatedEntity)) {
      logger.warn('updateEntitiesStore: Incomplete entity, unable to update store', updatedEntity)
      continue
    }

    // Only update the store if the entity is newer than the existing one
    if (existing && entity.updatedAt >= existing.updatedAt) {
      dispatch(entitiesApi.util.upsertQueryData('loadEntity', { id: entity.id }, updatedEntity))
    }
    if (!updateParent) {
      continue
    }

    const topEntities = entitiesApi.endpoints.loadAllEntities.select({})(state).data

    if (topEntities) {
      // This causes an infinite for the tree view
      dispatch(
        entitiesApi.util.updateQueryData('loadAllEntities', {}, draft => {
          const index = draft.findIndex(e => e.id === updatedEntity.id)
          if (index > -1) {
            draft.splice(index, 1)
          }
          if (!updatedEntity.parentId || updatedEntity.parentId === 'ROOT') {
            draft.push(updatedEntity)
          }
        })
      )
    }

    // Add to the new child list
    const newParentId = updatedEntity.parentId
    if (newParentId) {
      // Remove from the old child list, for both networkFirst and cache-first entities
      // @todo this is a huge hack, and we need to get all the variants of the possible invocations
      const newChildEntities = [
        ...(entitiesApi.endpoints.loadChildEntities.select({ parentId: newParentId, networkFirst: false })(state)
          .data ?? []),
        ...(entitiesApi.endpoints.loadChildEntities.select({
          parentId: newParentId,
          networkFirst: false,
          type: updatedEntity.type,
        })(state).data ?? []),
        ...(entitiesApi.endpoints.loadChildEntities.select({
          parentId: newParentId,
          networkFirst: true,
          type: updatedEntity.type,
        })(state).data ?? []),
        ...(entitiesApi.endpoints.loadChildEntities.select({ parentId: newParentId, networkFirst: true })(state).data ??
          []),
      ]
      if (newChildEntities.length > 0) {
        // Update both networkFirst and cache-first entries
        const updateFn = (draft: Draft<Persisted<Entity>[]>) => {
          // Remove the entities that have been removed, and the entity itself
          const idsToRemove = [...removedIds, updatedEntity.id]
          for (const idToRemove of idsToRemove) {
            const index = draft.findIndex(e => e.id === idToRemove)
            if (index > -1) {
              draft.splice(index, 1)
            }
          }
          draft.push(updatedEntity)
        }
        // If we have not loaded children before we need to upsert
        dispatch(
          entitiesApi.util.updateQueryData(
            'loadChildEntities',
            { parentId: newParentId, networkFirst: false },
            updateFn
          )
        )
        dispatch(
          entitiesApi.util.updateQueryData('loadChildEntities', { parentId: newParentId, networkFirst: true }, updateFn)
        )
      }
      const partialChildren = entitiesApi.endpoints.loadChildEntitiesPartial.select({
        parentId: newParentId,
      })(state).data
      if (partialChildren) {
        const updateFn = (draft: Draft<{ entities: Persisted<Entity>[] }>) => {
          const index = draft.entities.findIndex(e => e.id === entity.id)
          if (index > -1) {
            draft.entities.splice(index, 1)
          }
          draft.entities.push(updatedEntity)
        }
        dispatch(entitiesApi.util.updateQueryData('loadChildEntitiesPartial', { parentId: newParentId }, updateFn))
      }
    }
    // If the updated and existing parentIds are the same, we don't need to update the child entities for the old
    if (updatedEntity.parentId === existing?.parentId) {
      continue
    }

    // We need to update the child entities of both the existing parentId and the updated parentId
    const oldParentId = existing?.parentId

    if (oldParentId) {
      // Remove from the old child list
      const oldChildEntities = [
        ...(entitiesApi.endpoints.loadChildEntities.select({
          parentId: oldParentId,
          networkFirst: false,
        })(state).data ?? []),
        ...(entitiesApi.endpoints.loadChildEntities.select({
          parentId: oldParentId,
          networkFirst: true,
        })(state).data ?? []),
      ]

      logger.debug('oldChildEntities', oldChildEntities)

      if (oldChildEntities.length > 0) {
        const updateFn = (draft: Draft<Persisted<Entity>[]>) => {
          // Remove the entities that have been removed, and the entity itself
          const idsToRemove = [...removedIds, updatedEntity.id]
          for (const idToRemove of idsToRemove) {
            const index = draft.findIndex(e => e.id === idToRemove)
            if (index > -1) {
              draft.splice(index, 1)
            }
          }
        }
        // If we have not loaded children before we need to upsert
        dispatch(
          entitiesApi.util.updateQueryData(
            'loadChildEntities',
            { parentId: oldParentId, networkFirst: false },
            updateFn
          )
        )
        dispatch(
          entitiesApi.util.updateQueryData('loadChildEntities', { parentId: oldParentId, networkFirst: true }, updateFn)
        )
      }
      const partialChildren = entitiesApi.endpoints.loadChildEntitiesPartial.select({
        parentId: oldParentId,
      })(state).data
      if (partialChildren) {
        dispatch(
          entitiesApi.util.updateQueryData('loadChildEntitiesPartial', { parentId: oldParentId }, draft => {
            const index = draft.entities.findIndex(e => e.id === entity.id)
            if (index > -1) {
              draft.entities.splice(index, 1)
            }
          })
        )
      }
    }
  }
}

// Define a service using a base URL and expected endpoints

const tagTypes = ['Entity', 'EntityEdges', 'EntityBacklinks', 'ChildEntities', 'RootEntities'] as const
type TagType = (typeof tagTypes)[number]
export const entitiesApi = createApi({
  reducerPath: 'entities-api',
  tagTypes,
  baseQuery,
  keepUnusedDataFor: 60,

  endpoints: builder => ({
    loadEntity: builder.query<Persisted<Entity>, { id: string }>({
      providesTags: (result, error, id) => [{ type: 'Entity', id: id.id }],

      query: ({ id }) => {
        const headers: Record<string, string> = {}
        return {
          url: `entities/${id}`,
          headers,
        } satisfies FetchArgs
      },
      onQueryStarted: async (ids, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(({ meta }) => {
          const eTag = meta?.eTag
          if (eTag) {
            dispatch(entitiesSlice.actions.setEtag({ id: ids.id, eTag }))
          }
        })
      },
    }),
    timeTravelChanges: builder.query<EntityUpdate[], { id: string }>({
      query: ({ id }) => `entities/${id}/timetravel`,
    }),
    timeTravel: builder.query<Persisted<Entity>, { entityId: string; createdAtId: string }>({
      // keepUnusedDataFor: 0,
      query: ({ entityId, createdAtId }) => `entities/${entityId}/timetravel?at=${createdAtId}`,
    }),

    loadEntityEdges: builder.query<Edge[], { source: string; target?: string }>({
      providesTags: (result, error, { source }) => [{ id: source, type: 'EntityEdges' }],
      query: ({ source, target }) => `entities/${source}/edges${target ? `?target=${target}` : ''}`,
    }),

    loadEntityEdgesByIds: builder.query<Edge[], { sources: string[]; target?: string; relation?: EdgeType }>({
      // providesTags: (result, error, { source, target, relation }) => [{ id: source, type: 'EntityEdges' }],
      query: ({ sources, target, relation }) =>
        `entities/edges?sourceIds=${sources.join(',')}${target ? `&target=${target}` : ''}${relation ? `&relation=${relation}` : ''}`,
    }),

    /** Load the entities on the target side of an edge */
    loadEdgeEntitiesByIds: builder.query<
      Persisted<Entity>[],
      { sourceIds: string[]; target?: string; relation?: EdgeType }
    >({
      // providesTags: (result, error, { source, target, relation }) => [{ id: source, type: 'EntityEdges' }],
      query: ({ sourceIds, target, relation }) =>
        `entities/edge-entities?sourceIds=${sourceIds.join(',')}${target ? `&target=${target}` : ''}${relation ? `&relation=${relation}` : ''}`,
    }),

    createEntityEdge: builder.mutation<Edge, { edge: Edge }>({
      // we maintain these manually in onQueryStarted
      invalidatesTags: edge => {
        if (!edge) {
          return []
        }
        // logger.debug('Create edge invalidates EntityEdges tag', edge.source)
        return [
          { type: 'EntityEdges', id: edge.source },
          { type: 'EntityBacklinks', id: edge.target },
        ]
      },

      query: ({ edge }) => ({
        method: 'POST',
        url: `entities/${edge.source}/edges`,
        body: edge,
      }),
      onQueryStarted: async ({ edge }, api) => {
        const { dispatch } = api

        /** If the entity has an id we push it to the child list immediately */
        const { source, target } = edge

        // If we have not loaded children before we need to upsert
        const existingEdges =
          entitiesApi.endpoints.loadEntityEdges.select({ source, target })(api.getState()).data ?? []
        const existingBacklinks =
          entitiesApi.endpoints.loadEntityBacklinks.select({ id: target })(api.getState()).data ?? []
        dispatch(entitiesApi.util.upsertQueryData('loadEntityEdges', { source, target }, [...existingEdges, edge]))
        dispatch(entitiesApi.util.upsertQueryData('loadEntityBacklinks', { id: target }, [...existingBacklinks, edge]))
      },
    }),
    deleteEntityEdge: builder.mutation<void, Edge>({
      invalidatesTags: (result, error, { source, target }) => {
        logger.debug(`Delete edge invalidates EntityEdges ${source} tag and EntityBacklinks ${target}`)
        return [
          { type: 'EntityEdges', id: source },
          { type: 'EntityBacklinks', id: target },
        ]
      },

      query: edge => ({
        method: 'DELETE',
        url: `entities/${edge.source}/edges`,
        body: edge,
      }),
    }),
    loadEntityBacklinks: builder.query<Edge[], { id: string }>({
      providesTags: (result, error, id) => [{ type: 'EntityBacklinks', id: id.id }],

      query: ({ id }) => `entities/${id}/backlinks`,
    }),
    loadEntities: builder.query<Persisted<Entity>[], { ids: string[] }>({
      providesTags: result => {
        const tags = result?.map(i => ({ type: 'Entity' as const, id: i.id })) ?? []
        return tags
      },
      query: ({ ids }) => `entities/?ids=${encodeURIComponent(dedupe(ids).join(','))}`,
      onQueryStarted: async (ids, { dispatch, queryFulfilled, getState }) => {
        queryFulfilled.then(({ data }) => {
          updateEntitiesStore({ entities: data, dispatch, state: getState(), updateParent: true })
        })
      },
    }),
    loadEntityPublic: builder.query<{ entity: Persisted<Entity>; authorizedUrl: string | null }, { id: string }>({
      providesTags: (result, error, { id }) => [{ type: 'Entity' as const, id }],
      query: ({ id }) => `entities/${id}/public`,
      transformResponse: async (entity: Persisted<Entity>, meta) => {
        const authorizedUrl = isStoredObject(entity) ? meta?.response?.headers.get('authorized-url') ?? null : null
        return { entity, authorizedUrl }
      },
    }),
    loadEntityHistory: builder.query<EntityVersion[], { id: string }>({
      query: ({ id }) => `entities/${id}/history`,
    }),
    restoreEntityVersion: builder.mutation<Persisted<Entity>, { version: EntityVersion }>({
      invalidatesTags: (result, error, { version }) => [{ type: 'Entity', id: version.id }],
      query: ({ version }) => `entities/${version.id}/restore?version=${version.version}`,
      onQueryStarted: async ({ version }, { dispatch, queryFulfilled }) => {
        /** Clear the YDoc cache for the entity */
        clearYDocCache(version.id)
        queryFulfilled.then(({ data: entity }) => {
          dispatch(entitiesApi.util.upsertQueryData('loadEntity', { id: entity.id }, entity))
        })
      },
    }),
    /** Restore to a previous YDoc. Just an update - a bit of a hack...p */
    entityTimeTravelRestore: builder.mutation<Persisted<Entity>, { id: string; timeTravelTo: string }>({
      invalidatesTags: (result, error, { id }) => [{ type: 'Entity', id }],

      query: ({ timeTravelTo, id }) => ({
        url: `entities/${id}/restore?timeTravelTo=${timeTravelTo}`,
      }),
      onQueryStarted: async ({ id }, { dispatch, queryFulfilled }) => {
        /** Clear the YDoc cache for the entity */
        clearYDocCache(id)
        queryFulfilled.then(({ data }) => {
          dispatch(entitiesApi.util.upsertQueryData('loadEntity', { id: data.id }, data))
        })
      },
    }),
    loadAllEntities: builder.query<
      Persisted<Entity>[],
      {
        partial?: boolean
        trash?: boolean
        fields?: string[]
      } | void
    >({
      providesTags: ['RootEntities', { type: 'Entity' }],
      query: ({ partial, trash, fields = [] } = loadAllProps) =>
        `entities/?fields=${fields.join(',')}${partial ? '&partial=true' : ''}${trash ? '&trash=true' : ''}`,
      onQueryStarted: async (_, { dispatch, queryFulfilled, getState }) => {
        queryFulfilled.then(({ data: entities }) => {
          /** Register the data in the loadEntity cache */
          updateEntitiesStore({ entities, dispatch, state: getState(), updateParent: false })
        })
      },
    }),
    /** Partial variant of loadAll, typically used for trash */
    loadAllEntitiesPartial: builder.query<
      { entities: Persisted<Entity>[]; resumeKey: StartKey | null },
      {
        partial?: boolean
        trash?: boolean
        fields?: string[]
        limit?: number
        startKey?: string | null
      } | void
    >({
      providesTags: ['RootEntities', { type: 'Entity', id: PARTIAL_LIST }],
      query: ({ partial, trash, fields = [], startKey, limit = 100 } = loadAllProps) => ({
        url: `entities/?fields=${fields.join(',')}${partial ? '&partial=true' : ''}${trash ? '&trash=true' : ''}${
          limit ? '&limit=' + limit : ''
        }${startKey ? '&startKey=' + startKey : ''}`,

        // never cache incremental loads
        headers: { 'Cache-Control': 'no-cache' },
      }),
      transformResponse: (baseQueryReturnValue, meta) => {
        const resumeKey = meta?.resumeKey ?? null
        return { entities: baseQueryReturnValue as Persisted<Entity>[], resumeKey }
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled, getState }) => {
        queryFulfilled.then(({ data: { entities } }) => {
          /** Register the data in the loadEntity cache */
          updateEntitiesStore({ entities, dispatch, state: getState(), updateParent: false })
        })
      },
    }),

    /** Child entities are used for e.g., chat comments. When loading incrementally, we need to invalidate */
    loadChildEntitiesPartial: builder.query<
      { entities: Persisted<Entity>[]; resumeKey: StartKey | null },
      {
        parentId: string
        type?: EntityType
        fields?: string
        limit?: number
        startKey?: string | null
      }
    >({
      providesTags: (result, error, { parentId }) => {
        const entityTags = result?.entities.map(r => ({ type: 'Entity' as const, id: r.id })) ?? []
        return [{ type: 'ChildEntities', id: parentId }, { type: 'ChildEntities', id: PARTIAL_LIST }, ...entityTags]
      },
      transformResponse: async (baseQueryReturnValue, meta) => {
        const resumeKey = meta?.resumeKey ?? null
        return { entities: baseQueryReturnValue as Persisted<Entity>[], resumeKey }
      },
      query: ({ parentId, type, fields, startKey, limit }) => ({
        url: `entities/?parentId=${parentId}${fields ? '&fields=' + fields : ''}${type ? '&type=' + type : ''}${
          limit ? '&limit=' + limit : ''
        }${startKey ? '&startKey=' + startKey : ''}`,
        // never cache incremental loads
        headers: { 'Cache-Control': 'no-cache' },
      }),
      onQueryStarted: async ({}, { dispatch, queryFulfilled, getState }) => {
        queryFulfilled.then(({ data: { entities } }) => {
          updateEntitiesStore({ entities, dispatch, state: getState(), updateParent: false })
        })
      },
    }),
    loadChildEntities: builder.query<
      Persisted<Entity>[],
      {
        parentId: string
        type?: EntityType
        fields?: string
        networkFirst: boolean
      }
    >({
      providesTags: (result, error, { parentId }) => {
        const entityTags = result?.map(r => ({ type: 'Entity' as const, id: r.id })) ?? []
        return [{ type: 'ChildEntities', id: parentId }, ...entityTags]
      },

      query: ({ parentId, type, fields, networkFirst }) => {
        const headers: Record<string, string> = {}
        if (networkFirst) {
          headers['Cache-Control'] = 'no-cache'
        }

        return {
          url: `entities/?parentId=${parentId}${fields ? '&fields=' + fields : ''}${type ? '&type=' + type : ''}`,
          headers,
        } satisfies FetchArgs
      },
      /** 
       * @note We used to ignore networkFirst in the cache key. This lead to subtle bugs. 
       * For example, user settings requires to load children networkFirst, otherwise we create duplicates. However, 
       * another hook loaded children without networkFirst, which caused the cache to be populated with old data.
      // serializeQueryArgs: ({ queryArgs: { parentId, type, fields }, endpointDefinition, endpointName }) =>
      //   defaultSerializeQueryArgs({ queryArgs: { parentId, type, fields }, endpointDefinition, endpointName }),

      */
      onQueryStarted: async ({}, { dispatch, queryFulfilled, getState }) => {
        queryFulfilled.then(({ data: entities }) => {
          updateEntitiesStore({ entities, dispatch, state: getState(), updateParent: false })
        })
      },
    }),
    createEntity: builder.mutation<
      Persisted<Entity>,
      { entity: Entity; parent: Persisted<Entity> | null; isPrivate?: boolean }
    >({
      invalidatesTags: result => {
        /** For root entities we need to invalidate the root */
        if (!result?.parentId) {
          return [{ type: 'RootEntities' as const }]
        }
        logger.debug('Create entity invalidates ChildEntities tag', result.parentId)
        return [{ type: 'ChildEntities', id: result?.parentId }]
      },
      query: ({ entity, parent, isPrivate = false }) => ({
        url: `entities/`,
        method: 'POST',
        body: decorateParentAndACLs({ entity, parent, isPrivate }),
      }),
      onQueryStarted: async ({ entity, parent }, api) => {
        const { dispatch, queryFulfilled } = api

        /** If the entity has an id we push it to the child list immediately */

        const id = entity.id
        if (id && parent) {
          updateEntitiesStore({
            entities: [
              {
                ...entity,
                id,
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString(),
                userId: parent.userId,
              },
            ],
            dispatch,
            state: api.getState(),
            updateParent: true,
          })
        }

        try {
          const { meta, data } = await queryFulfilled

          /** Store the eTag */
          const eTag = meta?.eTag

          /** Where to store this eTag? */
          if (eTag) {
            dispatch(entitiesSlice.actions.setEtag({ id: data.id, eTag }))
          }

          /** Update the parent cache instead of forcing a re-load */
          if (!data.parentId) {
            return
          }

          updateEntitiesStore({ entities: [data], dispatch, state: api.getState(), updateParent: true })
        } catch (e) {
          logger.error('Error creating entity', e)
        }
      },
    }),
    cloneEntity: builder.mutation<Persisted<Entity>, { entityId: string; name?: string }>({
      invalidatesTags: result => {
        /** For root entities we need to invalidate the root */
        if (!result?.parentId) {
          return [{ type: 'RootEntities' as const }]
        }
        return [{ type: 'ChildEntities', id: result?.parentId }]
      },
      query: ({ entityId, name }) => ({
        url: `entities/${entityId}/clone`,
        method: 'POST',
        body: {
          name,
        },
      }),
      onQueryStarted: async ({}, api) => {
        const { dispatch, queryFulfilled } = api

        try {
          const { meta, data } = await queryFulfilled

          /** Store the eTag */
          const eTag = meta?.eTag

          /** Where to store this eTag? */
          if (eTag) {
            dispatch(entitiesSlice.actions.setEtag({ id: data.id, eTag }))
          }

          /** Update the parent cache instead of forcing a re-load */
          if (!data.parentId) {
            return
          }

          updateEntitiesStore({ entities: [data], dispatch, state: api.getState(), updateParent: true })
        } catch (e) {
          logger.error('Error creating entity', e)
        }
      },
    }),
    updateEntity: builder.mutation<
      Persisted<Entity>,
      { id: string; partialEntity: Partial<Entity>; eTag: string | null; upsert?: boolean }
    >({
      /** Reload all entities (e.g., child entities) after updating an entity */
      invalidatesTags: (result, error, { partialEntity }) => {
        const invalidation: { type: TagType; id: string | undefined }[] = [
          /**
           * we try to avoid invalidating Entity here since we update from the Service Worker
           * invalidating here will result in stale data for a short time until the
           * SW message is received. So we don't invalidate here.
           */
          // { type: 'Entity', id },
          // { type: 'ChildEntities', id: result?.parentId },
        ]
        /**
         * Change the parentId or without parent => need to reload root
         * @note MUST filter out null values otherwise the invalidation breaks
         */
        if (!result?.parentId || partialEntity.parentId) {
          invalidation.push({ type: 'RootEntities', id: undefined })
        }
        return invalidation
      },
      query: ({ id, partialEntity, eTag, upsert = false }) => {
        const headers: Record<string, string> = {}
        /**
         * We need a strategy to update entities that have changed server-side.
         * Document updates are handled using Y - so no worries there.
         * However, we must include yDoc in the eTag, otherwise clients will not get the last content.
         * Single attribute updates are accepted below.
         * Multi-attribute updates will require eTag to be the same.
         */
        const numKeysUpdated = Object.keys(partialEntity).length
        if (eTag && numKeysUpdated > 1) {
          headers['if-match'] = eTag
        }
        const body = removeEntityReserved(partialEntity)
        return {
          url: `entities/${id}`,
          method: upsert ? 'PUT' : 'PATCH',
          headers,
          body,
        }
      },

      onQueryStarted: async ({ id, partialEntity }, api) => {
        const { dispatch, queryFulfilled } = api

        /** Optimistic update of the cache */
        const { data: existing } = entitiesApi.endpoints.loadEntity.select({ id })(api.getState())

        if (existing) {
          const updatedEntity = { ...existing, ...partialEntity }
          updateEntitiesStore({
            entities: [updatedEntity],
            dispatch,
            state: api.getState(),
            updateParent: true,
          })
        }

        try {
          const { meta, data } = await queryFulfilled

          /** Store the eTag */
          const eTag = meta?.eTag

          if (eTag) {
            dispatch(entitiesSlice.actions.setEtag({ id, eTag }))
          }

          updateEntitiesStore({ entities: [data], dispatch, state: api.getState(), updateParent: true })
        } catch (e) {
          logger.error('Error updating entity', e)
          /** Handle 412 errors specifically */
          const { error } = e as { error: { status: number; data: { statusCode: number; message: string } } }
          if (error.status === 412) {
            dispatch(
              notificationsSlice.actions.setAlert({
                severity: 'warning',
                title: 'Unable to save',
                message: `This entity has been updated from another device. Your recent changes may be lost. Try again.`,
              })
            )
            dispatch(entitiesApi.endpoints.loadEntity.initiate({ id }, { forceRefetch: true }))
          }
          // @todo re-introduce undo - need to fix updateEntitiesStore
          // patchResult1.undo()
        }
      },
    }),
    deleteEntity: builder.mutation<void, { entity: Persisted<Entity> }>({
      /** Reload all entities (e.g., child entities) after updating an entity */
      invalidatesTags: (result, error, { entity }) => [
        { type: 'RootEntities' },
        { type: 'ChildEntities', id: entity.parentId ?? undefined },
        { type: 'ChildEntities', id: PARTIAL_LIST },
        { type: 'Entity', id: entity.id },
        { type: 'Entity', id: PARTIAL_LIST },
      ],
      query: ({ entity }) => ({
        url: `entities/${entity.id}`,
        method: 'DELETE',
      }),
    }),
    transferEntity: builder.mutation<void, { entity: Persisted<Entity>; toUserId: string }>({
      invalidatesTags: (result, error, { entity }) => [
        { type: 'RootEntities' },
        { type: 'ChildEntities', id: entity.parentId ?? undefined },
        { type: 'ChildEntities', id: PARTIAL_LIST },
        { type: 'Entity', id: entity.id },
        { type: 'Entity', id: PARTIAL_LIST },
      ],
      query: ({ entity, toUserId }) => ({
        url: `entities/${entity.id}/transfer`,
        method: 'POST',
        body: { toUserId },
      }),
    }),
    /** Social API */
    share: builder.mutation<Persisted<ShareItem>, { item: Persisted<ShareItem>; acls: ACL[] }>({
      /**
       * Whenever we share an item, we need to reload entities from the server,
       * because child entities may have been updated.
       */
      invalidatesTags: (result, error, { item }) => [
        { type: 'Entity' as const, id: item.id },
        { type: 'ChildEntities' as const, id: item.id },
      ],

      query: ({ item, acls }) => ({
        url: `social/shares`,
        method: 'POST',
        body: { id: item.id, acls },
      }),
      onQueryStarted: async ({ item, acls }, { dispatch }) => {
        /** Update ACLs in local item */
        dispatch(
          entitiesApi.util.updateQueryData('loadEntity', { id: item.id }, draft => {
            Object.assign(draft, { acls })
          })
        )
      },
    }),

    invite: builder.mutation<Slug, { invite: Invite }>({
      query: ({ invite }) => ({
        url: `social/invite`,
        method: 'POST',
        body: invite,
      }),
    }),
  }),
})
