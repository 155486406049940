// Need to use the React-specific entry point to import createApi
import { createApi } from '@reduxjs/toolkit/query/react'
import { logger } from '@tunasong/models'
import type { WordInfo } from '@tunasong/models'
import type { ChatMessage, ChatMessageStream, ChatPayload, ChatResponse } from '@tunasong/ai-lib'
import invariant from 'tiny-invariant'
import z from 'zod'
import baseQuery from './base-query.js'

export interface NLP {
  syllables?: Record<
    string,
    {
      text: string
      syllables: string[]
      count: number
    }
  >
  nextWord: [{ score: number; token_str: string; sequence: string }]
}

/** @todo Zod schema */
export const NLPImageResponseSchema = z.object({
  filename: z.string(),
})
export type NLPImageResponse = z.infer<typeof NLPImageResponseSchema>

/** @todo refactor these */
export type NLPMessageSchema = ChatMessage
export type ChatCompletionRequestMessage = ChatPayload
export type NLPCompleteResponse = ChatResponse
export type { ChatMessage, ChatPayload, ChatResponse }

export const NLPEmbeddingsResponseSchema = z.object({
  tokens: z.number(),
  data: z.array(z.array(z.number())),
})
export type NLPEmbeddingsResponse = z.infer<typeof NLPEmbeddingsResponseSchema>

// Define a service using a base URL and expected endpoints
export const nlpApi = createApi({
  reducerPath: 'nlp-api',
  baseQuery,
  endpoints: builder => ({
    getNlp: builder.query<NLP, { text: string; offset?: number; nextText?: string; prevText?: string }>({
      query: ({ text, offset = 0, nextText = '', prevText = '' }) =>
        `nlp/?text=${text}&offset=${offset}&nextText=${nextText}&prevText=${prevText}`,
    }),
    getWord: builder.query<WordInfo, { word: string }>({
      query: ({ word }) => `words/${word}`,
    }),
    complete: builder.query<
      NLPCompleteResponse,
      { prompt?: string; messages?: unknown[]; temperature?: number; model?: string; providerName?: string }
    >({
      query: ({ prompt, model, temperature, messages, providerName }) => ({
        url: `nlp/complete`,
        method: 'POST',
        body: { prompt, temperature, messages, model, providerName },
      }),
    }),
    /** Streaming complete call */
    completeStream: builder.query<
      { pending: boolean; text: string } | null,
      {
        prompt?: string
        messages?: unknown[]
        temperature?: number
        model?: string
        providerName?: string
        // Use to ensure the cache is unique
        requestId: string
      }
    >({
      queryFn: async () => ({ data: null }),
      async onCacheEntryAdded(payload, { updateCachedData, cacheDataLoaded, cacheEntryRemoved, getState }) {
        // create a fetch request to the server to initiate a socket connection
        // @see https://developer.chrome.com/articles/fetch-streaming-requests/

        if (!payload) {
          return
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const token = (getState() as any).user.authData.idToken.jwtToken
        invariant(token, 'No token found')

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const streamingApiBaseUrl = (getState() as any).config.streamingApiBaseUrl

        try {
          await cacheDataLoaded

          const response = await fetch(`${streamingApiBaseUrl}/complete/`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })

          const reader = response.body?.pipeThrough(new TextDecoderStream()).getReader()
          invariant(reader, 'Reader is not defined')

          while (true) {
            const { value, done } = await reader.read()
            console.log('NLP API', { value, done })

            updateCachedData(draft => {
              const msg = draft ?? {
                text: '',
                pending: true,
              }
              const updatedCacheData = {
                ...msg,
                text: `${msg.text}${value ?? ''}`,
                pending: !done,
              }
              return updatedCacheData
            })

            if (done) {
              break
            }
          }
        } catch (e) {
          logger.error(e)
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
        // cacheEntryRemoved will resolve when the cache subscription is no longer active
        await cacheEntryRemoved
      },
    }),
    embeddings: builder.query<NLPEmbeddingsResponse, { text: string }>({
      query: ({ text }) => ({
        url: `nlp/embeddings`,
        method: 'POST',
        body: { text },
      }),
    }),
    chat: builder.query<NLPCompleteResponse, { messages: ChatCompletionRequestMessage[] }>({
      query: ({ messages }) => ({
        url: `nlp/chat`,
        method: 'POST',
        body: { messages },
      }),
    }),
    chatStream: builder.query<ChatMessageStream | null, ChatPayload>({
      queryFn: async () => ({ data: null }),
      async onCacheEntryAdded(payload, { updateCachedData, cacheDataLoaded, cacheEntryRemoved, getState }) {
        // create a fetch request to the server to initiate a socket connection
        // @see https://developer.chrome.com/articles/fetch-streaming-requests/

        if (!payload) {
          return
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const token = (getState() as any).user.authData.idToken.jwtToken
        invariant(token, 'No token found')

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const streamingApiBaseUrl = (getState() as any).config.streamingApiBaseUrl

        try {
          await cacheDataLoaded

          const response = await fetch(`${streamingApiBaseUrl}/chat/`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })

          const reader = response.body?.pipeThrough(new TextDecoderStream()).getReader()
          invariant(reader, 'Reader is not defined')

          while (true) {
            const { value, done } = await reader.read()

            updateCachedData(draft => {
              const msg = draft ?? {
                role: 'assistant',
                content: '',
                pending: true,
              }
              const updatedCacheData = {
                ...msg,
                content: `${msg.content}${value}`,
                pending: !done,
              }
              return updatedCacheData
            })

            if (done) {
              break
            }
          }
        } catch (e) {
          logger.error(e)
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
        // cacheEntryRemoved will resolve when the cache subscription is no longer active
        await cacheEntryRemoved
      },
    }),

    image: builder.query<NLPImageResponse, { prompt: string }>({
      query: ({ prompt }) => ({
        url: `nlp/image`,
        method: 'POST',
        body: { prompt },
      }),
    }),
  }),
})
